import { MediaData } from '@innedit/innedit';
import React, { FC, SyntheticEvent, useRef } from 'react';

import Button from '../../../../../../components/Button';

const ButtonAddMedia: FC<{
  boutique: firebase.default.firestore.DocumentSnapshot;
}> = ({ boutique }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnChange = (e: SyntheticEvent<HTMLInputElement>) => {
    if (e.currentTarget && e.currentTarget.files) {
      const { files } = e.currentTarget;
      const promises = [];
      for (let i = 0; i < files.length; i += 1) {
        const image = files.item(i);
        if (image) {
          promises.push(
            MediaData.uploadPhoto(image, {
              boutique,
            }),
          );
        }
      }

      Promise.all(promises)
        .then(values =>
          values.map(document => {
            if (!document.exists) {
              console.error("Le document n'existe pas");

              return false;
            }

            return true;
          }),
        )
        .catch(error => {
          // TODO afficher le message d'erreur -> Mettre en place un système de notification
          console.error(error.code, error.message);
        });
    }
  };

  const handleUploadNewMedias = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <>
      <input
        ref={inputRef}
        onChange={handleOnChange}
        style={{ display: 'none' }}
        type="file"
      />
      <Button icon="fa-plus" onClick={handleUploadNewMedias} />
    </>
  );
};

export default ButtonAddMedia;
