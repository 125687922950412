import { MediaData } from '@innedit/innedit';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';

import requireBoutique, {
  BoutiqueProps,
} from '../../../../../../utils/requireBoutique';
import ActionBar from '../../../../../CMS/components/ActionBar';
import CMSView from '../../../../../CMS/components/View';
import CMSList from '../../../../../CMS/components/View/List';
import ButtonAddMedia from './ButtonAddMedia';
import Item from './Item';

const MediasList: FC<BoutiqueProps> = ({ boutique }) => {
  const [medias, setMedias] =
    useState<firebase.default.firestore.QueryDocumentSnapshot[]>();
  const [nbParLigne, setNbParLigne] = useState<number>(5);

  useEffect(() => {
    let unsub: () => void;

    if (boutique) {
      unsub = MediaData.watch(
        querySnapshot => {
          setMedias(querySnapshot.docs);
        },
        {
          boutique,
        },
      );
    }

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [boutique]);

  const handleOnChangeNbParLigne = (e: SyntheticEvent<HTMLInputElement>) => {
    setNbParLigne(parseInt(e.currentTarget.value, 10));
  };

  return (
    <CMSView className="boutique__medias">
      <CMSList title="Médias">
        <ActionBar className="action-bar is-clearfix">
          <ul>
            <li className="navbar-item">
              <ButtonAddMedia boutique={boutique} />
            </li>
          </ul>

          <ul className="border-left navbar-right">
            <li className="navbar-item nbByRow">
              <input
                onChange={handleOnChangeNbParLigne}
                type="number"
                value={nbParLigne}
              />
            </li>
          </ul>
        </ActionBar>

        {medias && medias.length > 0 && (
          <ol>
            {medias.map(media => (
              <Item
                key={media.id}
                boutique={boutique}
                doc={media}
                nbParLigne={nbParLigne}
              />
            ))}
          </ol>
        )}
      </CMSList>
    </CMSView>
  );
};

export default requireBoutique(MediasList);
