import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import Medias from '../core/sysext/Boutique/containers/Media/components/List';
import Backoffice from '../core/sysext/CMS/containers/Backoffice';

const MediasPage: FC<PageProps> = props => {
  const { location } = props;

  return (
    <Backoffice location={location}>
      <Medias {...props} />
    </Backoffice>
  );
};
export default MediasPage;
