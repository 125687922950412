import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import ViewTitle from '../../styles/View/Title';
import Split from './Split';

const Div = styled.div`
  margin: ${props => (props.theme.mobile ? '1.5rem 0.75rem' : '1.5rem 2rem')};
`;

const View = props => {
  const { children, type, title, className } = props;

  switch (type) {
    case 'split':
      if (children.length > 1) {
        return <Split {...props} />;
      }

      return (
        <Div className="help error">
          Il manque un enfant pour afficher cette vue
        </Div>
      );

    default:
      return (
        <Div className={className}>
          {title && <ViewTitle>{title}</ViewTitle>}
          {children}
        </Div>
      );
  }
};

View.defaultProps = {
  className: null,
  title: null,
  type: null,
};

View.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
};

export default View;
