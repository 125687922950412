import PropTypes from 'prop-types';
import React from 'react';

const Split = ({ children }) => (
  <div className="cms-view-split">
    {children}
    {/* <div className="column"> */}
    {/* {props.children[0]} */}
    {/* </div> */}
    {/* <div className="column"> */}
    {/* {props.children[1]} */}
    {/* </div> */}
  </div>
);

Split.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Split;
